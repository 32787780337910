@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

$fontConfig: mat.define-typography-config(
  $font-family: 'Helvetica',
  $headline-5: mat.define-typography-level(26px, 30px, 600, 'Helvetica', 0em),
  $headline-6: mat.define-typography-level(20px, 24px, 600, 'Helvetica', 0em),
  $subtitle-1: mat.define-typography-level(18px, 22px, 600, 'Helvetica', 0em),
  $body-1: mat.define-typography-level(16px, 20px, 500, 'Helvetica', 0.0179em),
  $body-2: mat.define-typography-level(14px, 16px, 400, 'Helvetica', 0.0179em),
  $button: mat.define-typography-level(14px, 16px, 600, 'Helvetica', 0.0893em),
);
// .mat-h1 or .mat-headline-5	headline-5	<h1>
// .mat-h2 or .mat-headline-6	headline-6	<h2>
// .mat-h3 or .mat-subtitle-1	subtitle-1	<h3>
// .mat-h4 or .mat-body-1

// Foreground Elements

// Light Theme Text
$dark-text: #081226;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: #ffffff,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#192540, 20%);
$dark-bg-alpha-4: rgba(#192540, 0.04);
$dark-bg-alpha-12: rgba(#192540, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #192540;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core();

// Theme Config

body {
  --primary-color: #5169ff;
  --primary-lighter-color: #cbd2ff;
  --primary-darker-color: #374cff;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
  --green-color: #24ac05;
  --grey-color: #ccc;
  --orange-color: #ffa41c;
  --black2-color: #525a67;
  --link-color-light: #5169ff;
  --link-color-dark: #00dac5;
  --yellow-color: #ffd740;
  --purple-color: #9966cc;
}
$mat-primary: (
  main: #5169ff,
  lighter: #cbd2ff,
  darker: #374cff,
  200: #5169ff,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #081226;
  --accent-lighter-color: #ededed;
  --accent-darker-color: #040a17;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #081226,
  lighter: #b5b8be,
  darker: #040a17,
  200: #081226,
  // For slide toggle,
  contrast: (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);
$mat-dark-accent: (
  main: #ffffff,
  lighter: #e5e9ea,
  darker: #081226,
  200: #192540,
  // For slide toggle,
  contrast: (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-dark-accent: mat.define-palette($mat-dark-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast: (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: (
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
  ),
  typography: $fontConfig,
  density: -2,
);
$dark-theme: (
  color: (
    // primary: $theme-dark-primary,
    primary: $theme-primary,
    accent: $theme-dark-accent,
    warn: $theme-warn,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
  ),
);

// Theme Init
@include mat.all-component-themes($theme);
@include mtx.all-component-themes($theme);

.dark-theme {
  @include mat.all-component-colors($dark-theme);
  @include mtx.all-component-themes($dark-theme);
}

@include mat.typography-hierarchy($fontConfig);

body {
  --mtx-drawer-container-background-color: #fff;
  --mdc-checkbox-unselected-icon-color: #192540;
  --link-color: var(--link-color-light);
  --text-color: #000;
  --body-background-color: #f1f3f4;
  --second-background-color: #eee;
  --mdc-typography-body2-font-weight: 500;
  gallery {
    background-color: #fff !important;
  }

  .bdc-walk-popup {
    .container {
      background-color: #fff !important;
    }

    .header {
      color: unset !important;
    }

    .buttons button {
      color: var(--mdc-outlined-button-label-text-color) !important;
    }

    .arrow.mat-menu-below {
      &::after {
        border-bottom-color: #fff !important;
      }
    }
  }
}

body.dark-theme {
  --mtx-drawer-container-background-color: #192540;
  --link-color: var(--link-color-dark);
  --body-background-color: #081226;
  --second-background-color: #192540;
  --mdc-checkbox-selected-checkmark-color: #192540;
  --text-color: #fff;
  .mat-mdc-mini-fab.mat-accent {
    mat-icon {
      color: #192540;
    }
  }
  .ql-toolbar .ql-stroke {
    fill: none;
    stroke: #fff;
  }

  .ql-toolbar .ql-fill {
    fill: #fff;
    stroke: none;
  }

  .ql-toolbar .ql-picker {
    color: #fff;
  }
  gallery {
    background-color: #192540 !important;
  }

  .mat-mdc-card gallery {
    background-color: var(--mdc-elevated-card-container-color) !important;
  }

  .mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-label-text-color: #f1f3f4;
  }

  .mat-mdc-unelevated-button.mat-accent {
    --mdc-filled-button-label-text-color: #081226;
  }

  .mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-label-text-color: #f1f3f4;
  }

  .mat-mdc-raised-button.mat-accent {
    --mdc-protected-button-label-text-color: #081226;
  }

  .bdc-walk-popup {
    .container {
      background-color: #192540 !important;
    }

    &.arrow.mat-menu-below {
      &::after {
        border-bottom-color: #192540 !important;
      }
    }
  }
}

// Handle buttons appropriately, with respect to line-height
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #f1f3f4;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: #f1f3f4;
}

button,
a {
  &.mdc-button {
    padding: 0 10px;
    margin: 0;
    min-width: 3em;
    min-height: 3em;
    font-size: 16px;

    span {
      letter-spacing: 1px;
    }
  }
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Inter';
  }
}

.mat-mdc-slide-toggle,
.dark-theme .mat-mdc-slide-toggle,
.dark-theme .mat-mdc-slide-toggle.mat-accent {
  // --mdc-switch-selected-track-color: green;
  // --mdc-switch-unselected-track-color: gray;

  // --mdc-switch-selected-icon-color: yellow;
  // --mdc-switch-unlected-icon-color: black;
  --mdc-switch-selected-handle-color: var(--green-color);
  --mdc-switch-selected-pressed-handle-color: var(--green-color);
  --mdc-switch-selected-pressed-state-layer-color: var(--green-color);
  --mdc-switch-selected-hover-state-layer-color: var(--green-color);
  --mdc-switch-selected-hover-handle-color: var(--green-color);
  --mdc-switch-selected-focus-state-layer-color: var(--green-color);
  --mdc-switch-selected-focus-handle-color: var(--green-color);
  --mdc-switch-selected-track-color: #e0e0e0;
  --mdc-switch-selected-pressed-track-color: #e0e0e0;
  --mdc-switch-selected-hover-track-color: #e0e0e0;
  --mdc-switch-selected-focus-track-color: #e0e0e0;

  // --mdc-switch-selected-track-color: var(--green-color);
  // --mdc-switch-unselected-track-color: gray;

  // --mdc-switch-unselected-icon-color: var(--warn-color);
  --mdc-switch-unselected-handle-color: var(--warn-color);
  --mdc-switch-unselected-pressed-handle-color: var(--warn-color);
  --mdc-switch-unselected-pressed-state-layer-color: var(--warn-color);
  --mdc-switch-unselected-hover-state-layer-color: var(--warn-color);
  --mdc-switch-unselected-hover-handle-color: var(--warn-color);
  --mdc-switch-unselected-focus-state-layer-color: var(--warn-color);
  --mdc-switch-unselected-focus-handle-color: var(--warn-color);
}

.mat-mdc-card {
  --mdc-elevated-card-container-elevation: 0;
}

.mat-mdc-card.mat-elevation-z6 {
  @include mat.elevation(6);
}

.mat-mdc-card.mat-elevation-z8 {
  @include mat.elevation(8);
}

.mat-mdc-card.mat-elevation-z10 {
  @include mat.elevation(10);
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-size: 16px;
  &.success-snackbar {
    --mdc-snackbar-container-color: #2196f3;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.error-snackbar {
    --mdc-snackbar-container-color: #ff0000;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

// TABLE
.mat-mdc-cell.mdc-data-table__cell,
.mat-mdc-header-cell.mdc-data-table__header-cell {
  padding-right: 4px;
  padding-left: 4px;
}

// .mat-button-toggle {
//   background-color: mat-color($primary);
//   color: mat-color($primary, default-contrast);
// }

.mat-button-toggle-checked {
  background-color: var(--primary-lighter-color);
  // color: mat-color($accent, default-contrast);
}

// https://github.com/angular/components/issues/26094
// Effectively, Angular team broke elevation after migrating cards to MDC-based :(
@for $i from 0 through 24 {
  .mat-mdc-card.mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}
