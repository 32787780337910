@import 'scss/variables';

@import 'scss/theme';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--body-background-color);

  &.dark-theme {
    color: #fff;
  }
}

h1,
h2,
h3,
h4 {
  &:not(.no-important-margin-0) {
    margin: 0 !important;
  }
}

a {
  text-decoration: none;
  color: var(--link-color);
}

.pageContainer {
  padding-top: 40px;
  width: 90%;
  margin: 0 5%;
}

.subtitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.w-50 {
  width: 100% !important;

  @media (min-width: map-get($grid-breakpoints, md)) {
    width: 50% !important;
  }
}

.w-75 {
  width: 100% !important;

  @media (min-width: map-get($grid-breakpoints, md)) {
    width: 75% !important;
  }
}

.w-100 {
  width: 100%;
}

.hGroup {
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
}

.hCentered {
  align-items: center;
}

.hBottom {
  align-items: end;
}

.hSpacer {
  flex: 1 1 auto;
}

.vSpacer {
  flex: 2;
  display: flex;
}

.pb-24 {
  padding-bottom: 24px;
}

.hidden {
  display: none !important;
}

.custom-mat-expansion-panel-padding {
  .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 10px !important;
  }

  .mat-expansion-panel-header {
    padding: 3px 5px !important;
  }
}

.custom-mat-expansion-panel {
  box-shadow: none !important;
  border-bottom: 1px solid $grey2;

  .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0 0 20px 0 !important;
  }

  .mat-expansion-panel-header {
    padding: 3px 5px !important;
  }

  .mat-expansion-panel-header-title {
    margin-right: 8px !important;
  }
}

.noWrap {
  white-space: nowrap;
}

.fullWidth {
  width: 100%;
}

.filtersContainer {
  margin-top: 40px;
  margin-left: 60px;
  margin-right: 24px;
  display: flex;
  width: 264px;
  min-width: 264px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
  background: #fff;
  font-family: 'Inter', 'Trebuchet MS', 'Tahoma', 'sans-serif';
}

.filtersHeader {
  color: #081226;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;
}

.checkBox {
  color: #081226 !important;
  margin-left: -10px;
  --mat-option-selected-state-label-text-color: #081226 !important;
  --mdc-checkbox-selected-icon-color: #081226 !important;
  --mdc-checkbox-selected-focus-icon-color: #081226 !important;
  --mdc-checkbox-selected-hover-icon-color: #081226 !important;
  --mdc-checkbox-selected-focus-state-layer-color: #081226 !important;
  --mdc-checkbox-selected-hover-state-layer-color: #081226 !important;
  --mdc-checkbox-selected-pressed-state-layer-color: #081226 !important;
}

.checkBoxLabel {
  font-size: 16px;
}

.relativeInline {
  position: relative;
  display: inline-block;
}

.relativeInline .carousel {
  vertical-align: middle;
}

.relativeInline .carouselMobile {
  vertical-align: middle;
}

.loader-container {
  display: flex;
  justify-content: center;
  top: 60px;
  position: relative;
  width: 100%;
  height: 100%;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 12px;
  position: fixed;
  text-indent: -9999em;
  border-top: 1.1em solid var(--accent-lighter-color);
  border-right: 1.1em solid var(--accent-lighter-color);
  border-bottom: 1.1em solid var(--accent-lighter-color);
  border-left: 1.1em solid var(--accent-color);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.disabled-file-input {
  .mdc-text-field--disabled {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.38) !important;
    }

    .mdc-floating-label {
      color: rgba(0, 0, 0, 0.6) !important;

      .file-name {
        color: rgba(0, 0, 0, 0.87) !important;
      }
    }
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ql-container {
  height: 100px !important;
}

.product {
  .ql-container {
    min-height: 100px;
    max-height: 350px;
    height: auto !important;
    overflow: auto;
  }
}

.pre-line {
  white-space: pre-line;
}

.snackBar-success {
  color: white !important;
  background-color: var(--green-color);
  margin-bottom: 20px !important;

  .mat-simple-snackbar-action {
    color: white !important;

    .mat-button-wrapper {
      text-decoration: underline !important;
    }
  }
}

.snackBar-error {
  color: white !important;
  background-color: var(--warn-color);
  margin-bottom: 20px !important;

  .mat-simple-snackbar-action {
    color: white !important;

    .mat-button-wrapper {
      text-decoration: underline !important;
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

quill-editor.ng-invalid .ql-editor {
  border: 2px solid var(--warn-color);
}

.flex-full {
  display: flex;
  width: 100%;
}

.flex-spacer {
  flex: 1 1 auto;
}

#intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  video {
    width: 90%;
    max-width: 300px;
  }
}

mat-progress-bar {
  height: 100%;
  position: relative;
  z-index: 1;
}

.progress-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  color: #fff;
  pointer-events: none;
}

.confirm-text {
  text-align: center;
  line-height: 1.5;

  .innerhtml-color-red {
    font-size: 18px;
    color: red;
    text-transform: uppercase;
  }
}

app-body-order-list.odd, mobile-body-order-list.odd {
  .count-item-container {
    background-color: var(--mat-table-background-color) !important;
  }

  .address-item,
  .address-item-icon,
  app-product-category-ico-custom .mat-icon {
    background-color: var(--mat-table-background-color) !important;
    border-radius: 5px;
  }

  .comments-container {
    .comments-component {
      background-color: white;
    }
  }
}

app-body-order-list.even, mobile-body-order-list.even {
  .count-item-container {
    background-color: var(--body-background-color) !important;
  }

  .address-item,
  .address-item-icon,
  app-product-category-ico-custom .mat-icon {
    background-color: var(--body-background-color) !important;
    border-radius: 5px;
  }

  .comments-container {
    .comments-component {
      background-color: #edf0fb;
    }
  }
}

.custom-select {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading {
    border-color: transparent;
    display: none;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: transparent;
    border-radius: 25px;
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    height: 34px;
    padding: 6px;
  }

  .mat-mdc-form-field-flex {
    height: 34px !important;
  }

  .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
    height: 34px !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover {
    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        height: 34px !important;
        border-color: transparent;
      }
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-color: transparent;
  }
}
